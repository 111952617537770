import React, { Suspense, useEffect } from 'react'
import { useMixpanel } from 'react-mixpanel-browser'
import { Route, Routes, useLocation } from 'react-router-dom'
import FullPageLoading from '../components/FullPageLoading'
import { useUserInfo } from '../hooks/useUserInfo'
import NotFound from '../pages/NotFound'
import RouteElement from './components/RouteElement'
import { mapRouteComponents } from './mapRouteComponents'
import { mapRoutes } from './mapRoutes'

const MyRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
  const location = useLocation()
  const mixpanel = useMixpanel()
  const userInfo = useUserInfo()

  const routeArray = Object.entries(mapRoutes).map(([key, value]) => ({
    name: key,
    ...value,
  }))

  const currentRoute = routeArray.find(
    (route) => route.pathname === location.pathname,
  )

  useEffect(() => {
    mixpanel.track(`Visit${currentRoute?.component}`, {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
      ...userInfo,
    })
  }, [location, mixpanel, userInfo, currentRoute?.component])

  return (
    <Suspense fallback={<FullPageLoading />}>
      <Routes>
        <Route path="*" element={<NotFound />} />

        {Object.entries(mapRoutes).map(([key, val]) => (
          <Route
            key={key}
            path={val.pathname}
            element={
              <RouteElement
                component={mapRouteComponents[val.component]}
                layout={mapRouteComponents[val.layout]}
                hasPublicAccess={val.hasPublicAccess}
              />
            }
          />
        ))}
      </Routes>
    </Suspense>
  )
}

export default MyRoutes
